import React from "react";

import { images } from "../../constants";
import "./AboutUs.css";

const AboutUs = () => (
    <div className="app__aboutus app__bg flex__center section__padding" id="about">
        <div className="app__aboutus-overlay flex__center">
            <img src={images.G} alt="SimmerList_overlay" />
        </div>

        <div className="app__aboutus-content flex__center">
            <div className="app__aboutus-content_about">
                <h1 className="headtext__cormorant">What</h1>
                <img src={images.spoon} alt="about_spoon" className="spoon__img" />
                <p className="p__opensans">
                    Simmer List is more than just a culinary discovery platform; it's an innovative way to share and explore dining experiences. Leveraging AI, we also help you make environmentally conscious choices that align with your preferences, without feeling like a compromise. Create lists of your favorite places, and conveniently schedule visits that cater to dietary preferences.
                </p>
            </div>

            <div className="app__aboutus-content_knife flex__center d-none d-md-block">
                <img src={images.knife} alt="about_knife" />
            </div>

            <div className="app__aboutus-content_history">
                <h1 className="headtext__cormorant">Why</h1>
                <img src={images.spoon} alt="about_spoon" className="spoon__img" />
                <p className="p__opensans">
                    At the heart of Simmer List is a commitment to enrich the food experience while being eco-conscious. Our AI-driven approach not only connects food lovers but also promotes sustainability. Discover hidden gems, align your dining with your environmental values, and share these experiences, all through a platform that understands the importance of every choice you make.
                </p>
            </div>
        </div>
    </div>
);

export default AboutUs;
