import React, { Component } from 'react';

export class RestaurantPage extends Component {

    componentDidMount() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // iOS detection
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            window.location.replace('https://apps.apple.com/us/app/simmer-list/id1663659241');
        }
        // Android detection
        else if (/android/i.test(userAgent)) {
            window.location.replace('https://play.google.com/store/apps/details?id=com.simmerlistapp');
        }
    }

    render() {
        return (
            <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div>
                    <a href='https://play.google.com/store/apps/details?id=com.simmerlistapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                        <img height="48px" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                    </a>
                </div>
                <div>
                    <a href='https://apps.apple.com/us/app/simmer-list/id1663659241'>
                        <img style={{ padding: 8 }} height="48px" alt='Download on the App Store' src='/AppStoreBadge.svg' />
                    </a>
                </div>
            </div>
        );
    }
}
