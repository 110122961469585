import React, { useState, useEffect } from 'react';
import authService from './api-authorization/AuthorizeService'
import Moment from 'react-moment';
import { Button } from 'reactstrap';

export default function Admin() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        populateUserData();
    }, []);

    async function populateUserData() {
        const token = await authService.getAccessToken();
        const response = await fetch('admin/getusers', {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        console.log({ data })
        setUsers(data);
        setLoading(false)
    }

    function renderUsersTable(users) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Confirmed</th>
                        <th>Member Number</th>
                        <th>Blocked Until</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user =>
                        <tr key={user.id}>
                            <td>{user.email}</td>
                            <td>{user.name}</td>
                            <td>{user.emailConfirmed ? "True" : "False"}</td>
                            <td>{user.memberNumber}</td>
                            <td>{<Moment date={new Date(user.lockoutEnd)} />}</td>
                            <td>{new Date(user.lockoutEnd) < new Date() ?
                                <Button outline color="danger">Block for a year</Button> :
                                <Button outline color="primary">Allow</Button>}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
    let contents = loading
        ? <p><em>Loading...</em></p>
        : renderUsersTable(users);

    return (<div>
        <h1 id="tabelLabel" >Users</h1>
        <p>Here's where you can manage all {users.length} users.</p>
        {contents}
    </div >);

}