import React from "react";

import { SubHeading } from "../../components";
import { images } from "../../constants";
import "./Header.css";

const Header = (props) => (
    <div className="app__header app__wrapper section__padding" id="home">
        <div className="app__wrapper_info">
            <SubHeading title="Your Culinary Carbon Compass" />
            <h1 className="app__header-h1">Find Your Tribe</h1>
            <p className="p__opensans" style={{ margin: "2rem 0" }}>
                Simmer List is available for download.
            </p>
            <div style={{ flexDirection: 'row', display: 'flex' }}>
                <div>
                    <a onClick={() => { window.lintrk('track', { conversion_id: 15446876 }); }} href='https://play.google.com/store/apps/details?id=com.simmerlistapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                        <img height="48px" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                    </a>
                </div>
                <div>
                    <a onClick={() => { window.lintrk('track', { conversion_id: 15446876 }); }} href='https://apps.apple.com/us/app/simmer-list/id1663659241'>
                        <img style={{ padding: 8 }} height="48px" alt='Download on the App Store' src='/AppStoreBadge.svg' />
                    </a>
                </div>
            </div>
        </div>

        <div className="app__wrapper_img">
            <img src={images.welcome} alt="header_img" />
        </div>
    </div>
);

export default Header;
