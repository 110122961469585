import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            isAdmin: null,
            userName: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentDidUpdate() {
        if (this.state.isAuthenticated && this.state.isAdmin == null) {
            this.CheckAdmin();
        }
    }

    async CheckAdmin() {
        //Check if admin
        const token = await authService.getAccessToken();
        console.log({ token })
        const response = await fetch('/user/isadmin', {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ isAdmin: data })
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])

        this.setState({
            isAuthenticated,
            userName: user && user.name
        });

    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = `${ApplicationPaths.LogOut}`;
            const logoutState = { local: true };
            return this.authenticatedView(userName, profilePath, logoutPath, logoutState);
        }
    }

    authenticatedView(userName, profilePath, logoutPath, logoutState) {
        return (<Fragment>
            {this.state.isAdmin ? <NavItem>
                <NavLink tag={Link} style={{ color: "#dcca87" }} to="/admin">Admin</NavLink >
            </NavItem> : ""}
            <NavItem>
                <NavLink tag={Link} style={{ color: "#dcca87" }} to={profilePath}>Hello {userName}</NavLink>
            </NavItem>
            <NavItem>
                <NavLink replace tag={Link} style={{ color: "#dcca87" }} to={logoutPath} state={logoutState}>Logout</NavLink>
            </NavItem>
        </Fragment>);
    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} style={{ color: "#dcca87" }} to={registerPath}>Register</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} style={{ color: "#dcca87" }} to={loginPath}>Login</NavLink>
            </NavItem>
        </Fragment>);
    }
}
