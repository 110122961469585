import React, { Component } from 'react';
import { Container, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div className="app__bg">
                <NavMenu />
                <Container>
                    {this.props.children}
                </Container>
                <div style={{ borderTopWidth: 1, borderTop: 1, borderTopColor: 'black', padding: 20 }}>
                    <NavLink tag={Link} style={{ color: "#dcca87" }} to="/privacy">Privacy</NavLink>
                </div>
            </div>
        );
    }
}
