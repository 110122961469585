import React, { useState, useEffect } from 'react';
import authService from './api-authorization/AuthorizeService'

export function NIMATest() {
    // State to store the list of photos
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        // Function to fetch photos from the backend
        const fetchPhotos = async () => {
            try {
                const response = await fetch('https://api.simmerlist.com/home/NIMAPhotos');
                const data = await response.json();
                setPhotos(data);
            } catch (error) {
                console.error('Error fetching photos:', error);
            }
        };

        fetchPhotos();
    }, []);

    return (
        <div>
            <h1>Photos</h1>
            <div>
                {photos.length > 0 ? (
                    photos.map((photo, index) => (
                        <div key={index}>
                            <img src={"https://simmerlistimages.blob.core.windows.net/post-images/" + photo.photo} alt={`Photo ${index}`} />
                            <p>Index: {photo.index}</p>
                            <p>NIMA Score: {photo.nimaScore || 'Not available'}</p>
                        </div>
                    ))
                ) : (
                    <p>No photos available</p>
                )}
            </div>
        </div>
    );
}
