import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { Privacy } from "./components/Privacy";
import { EULA } from "./components/EULA";
import { Home } from "./components/Home";
import Admin from "./components/Admin";
import { Download } from './components/Download';
import { NIMATest } from './components/NIMATest';
import { List } from './components/List';
import { RestaurantPage } from './components/RestaurantPage';

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/privacy',
        element: <Privacy />
    },
    {
        path: '/eula',
        element: <EULA />
    },
    {
        path: '/d',
        element: <Download />
    },
    {
        path: '/NIMATest',
        element: <NIMATest />
    },
    {
        path: '/list/*',
        element: <List />
    },
    {
        path: '/restaurantPage/*',
        element: <RestaurantPage />
    },
    {
        path: '/admin',
        requireAuth: true,
        element: <Admin />
    },
    ...ApiAuthorizationRoutes
];

export default AppRoutes;
